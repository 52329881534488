<template>
  <div class="content mod-agency agency-home">
    <div class="mod-banner" style="height: 461.205px">
      <!--焦点图-->
      <Banner />
      <!--end 焦点图-->
    </div>
    <div class="mod-affiliate">
      <div class="affiliate-tab">
        <ul>
          <li
            @click="tabChange(0)"
            :class="{ current: tabIndex == 0 }"
            target="partnership-info"
          >
            Partnership
          </li>
          <li
            @click="tabChange(1)"
            :class="{ current: tabIndex == 1 }"
            target="commision-info"
          >
            Commissions
          </li>
          <li
            @click="tabChange(2)"
            :class="{ current: tabIndex == 2 }"
            target="contact-info"
          >
            Contact Us
          </li>
        </ul>
      </div>
      <div class="agency-content">
        <div class="aff-info partnership-info" v-show="tabIndex == 0">
          <div>
            <h3 class="mt-0">How it works?</h3>
            <p>
              It takes little effort to master a good command of the operating
              process. Please refer to the information below.
            </p>
            <h3 class="mt-1">What is an affiliate program?</h3>
            <p>
              An affiliate program is a strategic cooperation relationship
              between you and Fun88. To put it simply, it is a cooperation
              program from which you gain returns and dividends in accordance
              with the stakes your referred players have laid down. The more
              players you refer, and the more stakes they lay down, the more
              profits you will get.
            </p>
            <h3>How to make profits?</h3>
            <p>
              Firstly, open an affiliate account as required. Please click here
              to start your&nbsp;registration, your application will be soon
              approved as long as the submitted information is accurate and
              complete. And you will get an affiliate account for free. Then,
              log in our partner interface and start your business. A unique
              code will distinguish the players you refer from those by other
              partners. Commission is paid to you according to the net profits
              the referred players produce.
            </p>
            <h3>How to start?</h3>
            <p>
              A typical affiliate program is operated through constructing a
              website. Your unique code is contained in this website through
              which your visitors enter Fun88 website.
            </p>
            <p>
              Not all affiliate partners own a website, though most affiliate
              programs are operated through it. So, at Fun88, all will be under
              your control in designing your website. And all you have to do is
              to make best use of your talents and creativity to reach more
              players. Place ads on magazines and/or newspaper, send emails to
              members, or any other ways that will prove expeditious and
              efficient.
            </p>
            <p>
              Please note: You are not supposed to send any emails to any
              members without their permission. Otherwise, your affiliate
              account will be terminated, and any earnings are forfeited.
            </p>
            <h3>How to develop?</h3>
            <p>
              As an affiliate of Fun88, you are free to log in our affiliate
              website, a systemized platform from which you can check on your
              profits and the number of players you’ve referred. You can also
              download and browse all information available here.
            </p>
          </div>
        </div>
        <div class="aff-info commision-info" v-show="tabIndex == 1">
          <div>
            <center><h3>FUN88 Affiliate Revenue Share</h3></center>
            <p>
              1. You will earn an initial rate up to 35% revenue share under the
              Fun88 Affiliate Program as provided below. The more real money
              players &amp; revenue you generate, the more you will earn. You
              will be paid a recurring profit from your players month after
              month for as long as their gaming activity continues and your
              Agreement with Fun88 remains valid and subsisting as per its terms
              and conditions.
            </p>
            <p>
              2. We will pay you a revenue share which is a percentage of the
              total monthly Net Revenue from play on our site generated as a
              result of all Real Money Players you refer under the Agreement.
              The more Real Money Players you refer and the more revenue these
              players generate, the more you will earn. You will be paid a
              recurring profit from your players month after month for as long
              as their gaming activity continues and your agreement with us
              remains valid and has not been terminated in accordance with its
              terms.
            </p>
            <br />
            <center><h3>COMMISSION STRUCTURE</h3></center>
            <p>We have a Multi-Tier Commission Structure:</p>
            <table width="1160" class="form-t m-t-20">
              <tbody>
                <tr>
                  <th>
                    Total Sportsbook, Virtual, Live Casino and Slot Net Win for
                    the Month (From The First Day To The Last Day Of Each Month)
                  </th>
                  <th>Minimum Active Players Directly under You to Qualify</th>
                  <th>Your Commission Percentage Rate</th>
                </tr>
                <tr>
                  <td>Rs 1 – 380,000</td>
                  <td>10</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>Rs 380,001 - Rs 2,280,000</td>
                  <td>20</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>&gt; Rs 2,280,000</td>
                  <td>40</td>
                  <td>35%</td>
                </tr>
              </tbody>
            </table>

            <table width="1160" class="form-t m-t-20">
              <tbody>
                <tr>
                  <th>
                    Total Poker Revenue for the Month (from the first day to the
                    last day of each month)
                  </th>
                  <th>Minimum Active Players Directly under You to Qualify</th>
                  <th>Your Commission Percentage Rate</th>
                </tr>
                <tr>
                  <td>Rs 1 – 760,000</td>
                  <td>10</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>Rs 760,001 - Rs 1,900,000</td>
                  <td>20</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>Rs 1,190,001 – 3,800,000</td>
                  <td>40</td>
                  <td>25%</td>
                </tr>
                <tr>
                  <td>&gt;Rs 3,800,000</td>
                  <td>40</td>
                  <td>35%</td>
                </tr>
              </tbody>
            </table>

            <table width="1160" class="form-t m-t-20">
              <tbody>
                <tr>
                  <th>
                    Exchange - Valid Bet Amount (From The First Day To The Last
                    Day Of Each Month)
                  </th>
                  <th>Minimum Active Players Directly under You to Qualify</th>
                  <th>Your Commission Percentage Rate</th>
                </tr>
                <tr>
                  <td>Rs 1 – 380,000</td>
                  <td>10</td>
                  <td>0.25%</td>
                </tr>
                <tr>
                  <td>Rs 380,001 - Rs 2,280,000</td>
                  <td>20</td>
                  <td>0.5%</td>
                </tr>
                <tr>
                  <td>&gt; Rs 2,280,000</td>
                  <td>40</td>
                  <td>0.75%</td>
                </tr>
              </tbody>
            </table>

            <table width="1160" class="form-t m-t-20">
              <tbody>
                <tr>
                  <th>
                    New First Time Depositors (Except Exchange &amp; Poker
                    Players)
                  </th>
                  <th>Your Bonus Commission Percentage Rate</th>
                </tr>
                <tr>
                  <td>1 – 15</td>
                  <td>2%</td>
                </tr>
                <tr>
                  <td>16 - 50</td>
                  <td>3%</td>
                </tr>
                <tr>
                  <td>&gt; 50</td>
                  <td>5%</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
            <p class="note">
              Please note: Apart from the number of the active players mentioned
              in the above table that the affiliate must meet, you should also
              ensure that every player you have referred is a genuine active
              player in Fun88. Fun88 has set a series of standards to audit the
              quality of every player. If you fail in meeting the standards,
              your commission will not be paid out.
            </p>
            <br />
            <p>
              3. Application of monthly commission percentage is by scale.
              Commission percentage will increase if Net Revenue and Minimum
              Active Players also increases. You will earn a revenue share based
              on the income generated from your referred Real Money Players.
              “Net Revenue” is defined as:
            </p>
            <br />
            <p>
              • Gross winnings of Sportsbook + Virtual + Live Casino + Slot -
              Player bonuses - Player payment fee - Admin cost = Net Winnings 1
            </p>
            <p>(Net Winnings 1) *Commission X% = <b>Net Earning 1</b></p>
            <br />
            <p>
              • Net revenue of Poker – Player bonuses – Player payment fee –
              Admin cost = Net Winnings 2
            </p>
            <p>(Net Winnings 2) *Commission X% = <b>Net Earning 2</b></p>
            <br />
            <p>
              • Exchange (total valid bet amount) * Commission X% – Player
              bonuses – Player payment fee – Admin cost= <b>Net Earning 3</b>
            </p>
            <br />
            <p>
              <b
                >Net Earnings 1 + Net Earnings 2 + Net Earnings 3 + Sub
                Affiliate Earnings = (Total Net Earnings)</b
              >
            </p>
            <br />
            <p>
              4. Your revenue share will be reviewed every month and is subject
              to an upgrade or downgrade depending on the scale rate. In order
              to be entitled to your revenue share, you need to maintain a
              minimum of ten (10) active Real Money Players per month.
            </p>
            <br />
            <p>
              5. <b>CPA Deals</b> - In this case, it will work on Fixed Budget
              or Fixed number of FTDs along with the other terms which will be
              mentioned by Fun88 Affiliate Manager.
            </p>
            <br />
            <p>
              5.1 If you are eligible to receive a CPA payment as per the terms
              agreed between you and Fun88, you will receive a one-time CPA
              payment by Fun88 in its sole discretion for every New Customer you
              refer who meets the following qualifications apart from the other
              agreed terms:
            </p>
            <p>
              5.2 The customer should meet the age and territory requirements to
              lawfully register an account on Fun88 site and participate in
              online gambling activity.
            </p>
            <p>
              5.3 The customer should create account with Fun88 and open an
              account subject to Fun88 Site's terms and conditions; and
            </p>

            <p>
              5.4 The customer deposits the minimum required amount and meets
              the minimum wagering activity requirements, as mentioned by Fun88.
            </p>
            <p>
              5.5 Where a Chargeback is received against a New Customer, or
              issuance of a credit to a New Customer occurs, the Affiliate will
              not be eligible for CPA Payment in respect of that New Customer,
              and any CPA Payment previously made to you in respect of such New
              Customer will be deducted from future payments due to you.
            </p>
            <p>
              5.6 We will not pay any CPA if affiliate’s player files a Charge
              back.
            </p>
            <br />
            <p>
              6. <b>Hybrid Deals</b> - In this case, CPA will be paid as per the
              agreed Terms &amp; Conditions and same goes for Revenue Share.
              Once the deal is over you are not entitled for any CPA, however,
              Rev share is entitled for a period of 6 months or as discussed
              between the parties where in activity must be there in your
              account with a minimum of 10 new players.
            </p>
            <br />
            <p>
              7.If you fail to continuously promote the Site by having no new
              signups and/or removal of Fun88 banners in your affiliate site,
              this will be deemed as a breach of contract and will automatically
              lead to termination of this Agreement and any further claims on
              future revenue share are deemed waived.
            </p>
            <br />
            <p>
              8. <b>Fun88 Sub-Affiliate</b> - Table below gives an example to
              show you how your commission earned from your referred affiliates
              is calculated. Suppose you have referred 10 affiliates and each of
              your referred affiliates also brings in 10 affiliates and so on.
              You will get 10% from each of your affiliates' commission. Your
              referred affiliates will get 10% from their referred affiliates,
              which will contribute to your commission as well. For example, you
              refer affiliate A who refers affiliate B who then refers C. If C
              earns a commission of Rs1,000,000 this month, 10% of this
              Rs1,000,000 which is Rs100,000 will be paid to B. Then, 10% of B's
              Rs100,000 will be paid to A, which is Rs10,000. Finally, 10% of
              A's Rs10,000 will be paid to you, which is Rs1,000. See the table
              below.
            </p>
            <p>
              <br />
            </p>
            <p class="note">(Note: The decimal point is rounded off)</p>
            <table width="1160" class="form-t m-t-20">
              <tbody>
                <tr>
                  <th></th>
                  <th>Sub Affiliate</th>
                  <th>Sub Affiliate's Income</th>
                  <th>Your Commission at 10% (Rs)</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Affiliate A</td>
                  <td>690,000</td>
                  <td>69,000</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Affiliate B</td>
                  <td>388,000</td>
                  <td>38,800</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Affiliate C</td>
                  <td>500,000</td>
                  <td>50,000</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Affiliate D</td>
                  <td>1,500,000</td>
                  <td>150,000</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Affiliate E</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <p>
              Your sub affiliate commission will be :
              69,000+38,800+50,000+150,000=307,800 Rs
            </p>
            <br />
            <p>
              Thus from the above chart and tables we can conclude that the more
              players you bring in to play and wager on our site and the more
              affiliates you refer to our site, the larger the commission you
              can expect to earn. So bring in as many players and affiliates as
              you can, you will be rewarded with infinite commission.
            </p>
            <br />
            <p class="note">
              *if you wish to introduce a sub-affiliate, feel free to contact
              us.
            </p>

            <br />

            <p>
              9. Deduction of the payments. If your players are involved in
              collusion or any other fraudulent issues, the manager of Fun88
              Affiliate Program has the right to deduct from your revenue share
              the withheld funds or remove the player’s account from your
              recorded referred players.
            </p>

            <br />

            <p>
              10. “Charge-back”: A credit/debit card holder discovers irregular
              transactions made on his/her Credit/Debit Card, which was not
              authorized by him/her. The credit/debit card holder then requests
              his/her bank to reverse these charges. Charge backs relate to
              fraudulent use by a third party of the credit card holder’s card
              or card number.
            </p>

            <br />

            <p>
              10.1 Should your referred player process a charge back, the
              disputed or charged-back revenue generated by your referred player
              will be forfeited and will not be included for purposes of
              computing the revenue share due to you for the current month.
            </p>

            <p>
              10.2 Should this deduction of the accumulated revenue exceed your
              current amount due, your balance will then revert to a negative
              balance, and you will have to earn revenue to cover the
              charge-back before you can start earning revenue again.
            </p>

            <p>
              10.3 Unlike with a player making a big winning, which only lasts
              one month, a charge back will stay due until the revenue generated
              by your other players has covered the amount due. We understand
              that this might be frustrating to the affiliates, but it has to be
              made clear that we can only pay you your revenue share from a
              percentage of your referred player’s profits and not for
              fraudulent revenue.
            </p>

            <br />

            <p>
              11. Revenue Share Payment. We will pay your commission by the
              middle of each month. However, an affiliate must maintain a
              minimum of ten (10) active real money players and other terms to
              be met for his commission to be processed and paid.
            </p>

            <br />
            <p>
              11.1 If the account is in the negative (i.e. customer winnings
              exceed customer loss), the negative amount will be carried over
              into the following month(s). Negative commission balances will be
              deducted from available commissions until the negative balance is
              cleared out. No payout will be issued until these criteria is met.
            </p>

            <br />

            <p>
              12. A processing fee will be deducted on bank wire payments to
              cover bank processing fees for affiliate pay-out balances of lower
              than USD200.
            </p>

            <br />

            <p>
              13. All payments will be paid in the currency of your choice when
              setting up your Affiliate Account. Where currency conversion is
              required, amount due will be converted using our currency rates at
              the time the revenue share was earned. Any change in currency must
              be submitted via a written request or an email to our Affiliate
              Managers. Any request via email must be done using affiliates’
              registered email with the Affiliate Program only.
            </p>
            <br />
            <p>
              14. Starting April 1, 2022, request for downline member transfer
              will be discontinued.
            </p>
            <br />
            <p>
              15. Management reserves the right to change or modify these terms
              and conditions at any time without prior notice.
            </p>
          </div>
        </div>
        <div class="aff-info contact-info" v-show="tabIndex == 2">
          <h3>
            <strong
              ><span style="color: #00a6ff"
                >Have any issues, concerns, or questions?</span
              ></strong
            >
          </h3>
          <h4>
            Feel free to contact us with your preferred communication method.
          </h4>
          <p></p>
          <ul class="clearfix">
            <li class="whatsapp-cs" style="display: none">
              <a href="https://wa.me/46140760800" target="_blank"
                ><img src="/in/static/images/faqs/ico_whatsapp.png" />
                <div>
                  <p><strong>Message Us Via Whatsapp</strong></p>
                  <p>+46140760800</p>
                </div>
              </a>
            </li>
            <li class="whatsapp-affiliate" style="display: block">
              <a href="https://wa.me/+441518081564" target="_blank"
                ><img src="../../assets/images/in/ico_whatsapp.png" />
                <div>
                  <p><strong>Message Us Via Whatsapp</strong></p>
                  <p>+44 151 808 1564</p>
                </div>
              </a>
            </li>

            <li class="telegram-cs" style="display: none">
              <a href="https://t.me/fun88india" target="_blank"
                ><img src="/in/static/images/faqs/ico_telegram.png" />
                <div>
                  <p><strong>Chat With Us on Telegram</strong></p>
                  <p>t.me/fun88india</p>
                </div>
              </a>
            </li>
            <li class="telegram-affiliate" style="display: block">
              <a href="https://t.me/Fun88_India_Affiliates" target="_blank"
                ><img src="../../assets/images/in/ico_telegram.png" />
                <div>
                  <p><strong>Chat With Us on Telegram</strong></p>
                  <p>t.me/Fun88_India_Affiliates</p>
                </div>
              </a>
            </li>

            <li class="skype" style="display: none">
              <a href="skype:live:.cid.3a09fa6549a414e2?chat" target="_blank"
                ><img
                  src="/in/fimg/i202009a8e14307682f4c79abd6131d5dedaf9e.png"
              /></a>
            </li>
            <li class="email-cs" style="display: none">
              <a href="mailto:cs@fun88india.com" target="_blank"
                ><img src="/in/static/images/faqs/ico_email.png" />
                <div>
                  <p><strong>Send Us an Email</strong></p>
                  <p>cs@fun88india.com</p>
                </div>
              </a>
            </li>
            <li class="email-affiliate" style="display: block">
              <a href="mailto:affiliate@fun88india.com" target="_blank"
                ><img src="../../assets/images/in/ico_email.png" />
                <div>
                  <p><strong>Send Us an Email</strong></p>
                  <p>affiliate@fun88india.com</p>
                </div>
              </a>
            </li>

            <li class="phone">
              <a class="phonelink" href="#">
                <img src="../../assets/images/in/ico_phone.png" />
                <div>
                  <p><strong>Give us a Call</strong></p>
                  <p>+91 22 5032 3347</p>
                </div>
              </a>
            </li>
          </ul>
          <p>Our agents will hear you out anytime of the day, 24/7.</p>

          <div class="contact-text page-text">
            <h5>Contact Us- Fun88 India</h5>
            <p>
              At
              <a href="https://www.fun88.com/in/" target="_blank">Fun88 India</a
              >, we offer multiple
              <a href="https://www.fun88.com/in/sports/" target="_blank"
                >sports betting</a
              >
              opportunities and
              <a href="https://www.fun88.com/in/live-casino/" target="_blank"
                >online casino games</a
              >
              excitement for bettors and punters. We know how important it is
              for you to have a stress-free experience while you choose to bet
              with us. Thus, we’re here for your service. If you have any
              issues, questions, or concerns, our Customer Care Executives are
              available to address them 24x7. Feel free to call us or email us
              at any given time. Our team is ready to take care of any issue you
              may have so that you can enjoy betting with secure banking on
              Fun88 India, while we take care of your concerns.
            </p>
            <h5>
              CALL US! FUN88 INDIA CUSTOMER CARE EXECUTIVES ARE HERE TO HELP YOU
            </h5>
            <p>
              To call us or email us, feel free to get in touch with the Fun88
              India customer care team as per any below mediums.
            </p>
            <p>
              Fun88 India Customer Care Number: +91 22 50323347 / Whatsapp
              number: + 46766923592
            </p>
            <p>
              Email ID: cs@fun88india.com<br />
              Telegram Channel: t.me/fun88india<br />
              Telegram Group: t.me/fun88IndiaGroup<br />
            </p>
            <h5>LIVE CHAT ASSISTANCE-CLICK TO CHAT!</h5>
            <p>
              Fun88 India’s Customer Care support is available on the clock,
              however, if you wish to get immediate assistance, we have live
              chat support as well! You will find the icon on the right-hand
              side of the website throughout so that your queries can be
              answered with a click of a button. You’ll agree that our customer
              care team is top-notch, friendly, and ready to assist you. So,
              play without fear!
            </p>
            <h5>HAVE MORE DOUBTS? WE’LL SOLVE THEM PRONTO!</h5>
            <p>
              Still, if you’re facing concerns, we’ve put together a
              <a href="https://www.fun88.com/in/help/faq/" target="_blank"
                >frequently asked question (FAQ) section</a
              >
              where you can get answers to clarify all your doubts about
              registration, how to make deposits, payment methods, withdrawals,
              etc.We’ve also put information about betting rules, event
              cancellation, postponement, as well as different betting options.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="affiliate-text page-text">
      <!-- Affiliate page  within <div class="affiliate-text page-text"> -->
      <div class="layout text-left" style="height: 300px">
        <h1>FUN88 – Best online betting affiliate programs</h1>
        <p class="mt-10">
          Fun88 offers some of the best
          <b
            >affiliate marketing programs for online casinos, sports betting and
            Exchange</b
          >. We are a leading betting platform in several markets of Asia,
          specifically India. We have partnerships with some of the top sports
          clubs worldwide, like Newcastle United Football Club and Tottenham
          Hotspur Football Club for engaging with fans, exclusive competitions,
          ticket sales, prize giveaways, like t-shirts, to reward soccer
          punters.
        </p>
        <p>
          We offer several betting options in sports like Cricket, Tennis,
          Soccer, Table Tennis, Baseball, Kabaddi and Ice Hockey. Fun88 covers
          all the major sports tournaments. Talking of cricket, we provide you
          betting opportunities for the Indian Premier League (IPL), the
          Pakistan Super League (PSL), the Caribbean Premier League (CPL), Pro
          Kabaddi League (PKL) and all the major cricketing tournaments. We’ve
          also launched BETB2B (1XBet) Sportsbook offering you choices you won’t
          get anywhere else! With 182+ sports lines, 25k+ events, and 16k+ live
          events, 1XBET is the widest sportsbook you can ever find.
        </p>
        <p>
          Fun88 also offers a range of online casino games, like Teen Patti,
          Andar Bahar, Live Roulette, Live Blackjack, and Live Baccarat, which
          allow bettors to play with a real dealer to make money. Although
          you’ll be playing on our website or mobile app, you’ll have the look
          and feel of a real casino. With our engaging interface and variety of
          playing options, punters enjoy an unforgettable casino experience on
          our platform.
        </p>
        <p>
          Bettors love coming to Fun88’s website. Recently, Fun88 has also
          started offering an online lottery. We’ve partnered with ‘Saba Sports’
          for this initiative. At our company, we’ve successfully run affiliate
          marketing programs over the years.
        </p>
        <h2 class="mt-20">Why should you join FUN88’s affiliate programs?</h2>
        <p class="mt-10">
          By partnering with Fun88, affiliates get access to a world-class
          online betting platform, which they can market to punters. They also
          get access to industry expertise and knowledge, which helps them grow
          their business and take online betting to a lot of people.
        </p>
        <p>
          Besides, it also gives a great opportunity to affiliates to be at the
          forefront of the online betting industry, which has seen tremendous
          growth in recent years.
        </p>
        <p>
          During the COVID-19 pandemic, online betting has allowed punters to
          entertain themselves by sitting at home and enabled affiliates to earn
          money by managing their business online.
        </p>
        <p>
          When affiliates partner with Fun88, they can rest assured that our
          team will provide them with total support and data-based insights for
          their long-term success. We are not a fly-by-night operator, as we
          bring decades of experience to the table.
        </p>
        <p>
          At Fun88, we are committed to building fruitful relationships with our
          affiliate network, and that’s the reason we are offering a 35%
          commission on all newly registered affiliates. We have a completely
          fuss-free approach for our affiliates, be it about providing affiliate
          services to them or helping them in booking new customers through our
          portal. This is what makes us one of the best
          <b>affiliate marketing programs for online casinos</b> and sports
          betting.
        </p>
        <p>
          Start your Fun88 affiliate journey today. Just
          <a
            href="https://www.fun88.com/in/affiliates/register/"
            target="_blank"
            >register with us</a
          >
          and fill in the details.
        </p>
        <div class="row mt-10">
          <div class="bg-white col col-lg">
            <img
              src="https://www.fun88.com/in/fimg/i202202b883e6ae0b134509829cb055233bcab7.png"
              style="display: block; margin: 2em auto; width: 90px"
            />
            <div
              style="
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #000;
              "
            >
              CRICKET
            </div>
            <p style="padding: 1em 1.5em 1.5em; text-align: center">
              Fun88 offers several betting opportunities to bet on India’s
              favourite sport - cricket. Be it ICC tournaments or bilateral
              series, if a cricket match is happening, you’ll certainly find a
              betting option on our website.
            </p>
          </div>
          <div class="bg-white col col-lg">
            <img
              src="https://www.fun88.com/in/fimg/i20220209ba859c29b243f7b46b778d4d5d1167.png"
              style="display: block; margin: 2em auto; width: 90px"
            />
            <div
              style="
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #000;
              "
            >
              FOOTBALL
            </div>
            <p style="padding: 1em 1.5em 1.5em; text-align: center">
              Punt on the English Premier League (EPL), the Euro Cup, the FIFA
              World Cup, and other football tournaments exclusively at Fun88.
            </p>
          </div>
          <div class="bg-white col col-lg">
            <img
              src="https://www.fun88.com/in/fimg/i202202f46f2be95316446c969e6601db0ecbf7.png"
              style="display: block; margin: 2em auto; width: 90px"
            />
            <div
              style="
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #000;
              "
            >
              TENNIS
            </div>
            <p style="padding: 1em 1.5em 1.5em; text-align: center">
              Be it grand slams or ATP tournaments, get exclusive betting action
              on Fun88. Bet on a tournament's winner, game spread, set spread,
              and many other betting options.
            </p>
          </div>
          <div class="bg-white col col-lg">
            <img
              src="https://www.fun88.com/in/fimg/i202202adcef3c2f5cd424caee252b8c7535b9d.png"
              style="display: block; margin: 2em auto; width: 90px"
            />
            <div
              style="
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #000;
              "
            >
              TEEN PATTI
            </div>
            <p style="padding: 1em 1.5em 1.5em; text-align: center">
              Play Teen Patti with a real dealer on Fun88's online platform.
              It's as good as playing Teen Patti in a real, brick-and-mortar
              casino.
            </p>
          </div>
          <div class="bg-white col col-lg">
            <img
              src="https://www.fun88.com/in/fimg/i20220266d4b821cdc04ceeab58c465b06bcc6e.png"
              style="display: block; margin: 2em auto; width: 90px"
            />
            <div
              style="
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: #000;
              "
            >
              ONLINE SLOT GAMES
            </div>
            <p style="padding: 1em 1.5em 1.5em; text-align: center">
              Enjoy the thrill of exciting slot games like Five Reel Drive,
              Break Da Bank Again, and Immortal Romance and win real money at
              Fun88.
            </p>
          </div>
        </div>

        <h2 class="mt-30">
          How to use content to become a successful affiliate marketer at FUN88?
        </h2>

        <p class="mt-10">
          If you want to be a successful affiliate marketer at Fun88, you need
          to understand that online content will form a central part of your
          work. You’ll be using various content marketing strategies to bring
          new punters to Fun88’s website and based on that, you’ll earn an
          income. There are a number of ways in which you can use the content
          for sports betting and online casinos.
        </p>

        <p>
          <b style="color: #00a6ff">Video content - </b> We all know that video
          content has seen tremendous growth in recent years. Several businesses
          have used YouTube videos to reach out to their potential customers. As
          an affiliate with an online betting platform, like Fun88, you can
          create videos around several topics about sports betting and live
          casinos. For example, you can make a video on many kinds of sports
          bets available in a cricket match. Through such videos, you can
          promote cricket bets available on Fun88 and help bring more traffic to
          our website.
        </p>

        <p>
          <b style="color: #00a6ff">Websites and blogs – </b> Although video
          content has caught on like anything, websites and blogs are still very
          much popular. You can create blogs on sports and casino betting to
          educate bettors on several aspects of gambling. Always keep in mind
          that certain concepts are a lot easier to explain in a blog than
          through a video. For example, it makes more sense to explain how
          various kinds of betting odds (American, European, British, Malaysia,
          Indonesian, Singaporean, etc.) work in a blog than in a YouTube video.
          You can use banner ads and other promotional methods to market Fun88’s
          products on such blogs.
        </p>

        <p>
          <b style="color: #00a6ff">Social media – </b> Social media platforms
          like Facebook, Twitter, and Instagram are powerful ways to connect
          with bettors online. You can use hashtags to find relevant threads and
          people. You can join online groups. Once you have a sizable following,
          you can even conduct webinars to educate punters about various betting
          products available online, thereby helping Fun88 in getting more
          traffic.
        </p>

        <p>
          <b style="color: #00a6ff">Email marketing – </b> Emails are still one
          of the most powerful ways to reach out to your target segment. As an
          affiliate marketer, you’ll be given a list of our products and links,
          which we’d like you to market to people in your network using emails.
          When you share the links given by us with your network, it will help
          us track how many punters you’re bringing to our website, which will
          also enable you to earn sizable referral income.
        </p>

        <p>
          <b style="color: #00a6ff">Bonuses, rewards, and offers – </b> Fun88
          has a number of exciting bonuses, lucky draws, and promotional offers
          listed on its website, which you can find by clicking
          <a href="https://www.fun88.com/in/promotions/" target="_blank"
            >this link</a
          >. We’ve got offers for sports betting and also live casino contests.
          You can curate content around these offers to bring new and existing
          bettors to Fun88’s platform.
        </p>

        <h2 class="mt-20">Steps to become a successful affiliate marketer</h2>

        <p class="mt-10">
          <b style="color: #00a6ff">Step 1:</b> Visit
          <a
            href="https://www.fun88.com/in/affiliates/register/"
            target="_blank"
            >this link</a
          >
          to register with us to become an affiliate.
        </p>

        <p>
          <b style="color: #00a6ff">Step 2:</b> Learn about various
          <a href="https://www.fun88.com/in/sports/" target="_blank"
            >sports bets</a
          >,
          <a href="https://www.fun88.com/in/live-casino/ " target="_blank"
            >live casino</a
          >,
          <a href="https://www.fun88.com/in/poker/ " target="_blank">poker</a>
          and
          <a href="https://www.fun88.com/in/slots/" target="_blank"
            >online slot</a
          >
          games we offer.
        </p>

        <p>
          <b style="color: #00a6ff">Step 3:</b> Create the right content by
          considering the characteristics of your target segment.
        </p>

        <p>
          <b style="color: #00a6ff">Step 4:</b> Bring quality traffic to our
          betting platform.
        </p>

        <p>
          <b style="color: #00a6ff">Step 5:</b> Start earning a commission for
          every visitor you bring that converts into a customer.
        </p>

        <p>
          *By becoming a customer with Fun88, players will have access to some
          of the best promotional offers in the industry. With fast live chat
          responses, quick deposits and withdrawals than any other website, it's
          very easy for players to make deposits and play.
        </p>

        <h2 class="mt-20">
          Frequently asked questions (FAQs) on casino affiliate marketing
        </h2>
        <div class="faq mt-20">
          <details open="">
            <summary>Why should I become an affiliate with Fun88?</summary>
            <div class="faq__content">
              <p>
                Fun88 is one of the leading betting platforms aimed at bettors
                in India and other markets of Asia. It provides betting options
                for a number of sports, including cricket, football, tennis,
                kabaddi, basketball, etc. Besides, it covers several prominent
                leagues and tournaments, like IPL, PSL, CPL, Euro Cup, NBA, etc.
                On any particular day, you can see several betting options
                available on sports on our platform. You can also find a variety
                of online casino games. Such a wide variety of betting options
                will help you in attracting punters easily to our website.
              </p>
            </div>
          </details>
          <details>
            <summary>
              What kind of commission can I earn by becoming an affiliate with
              Fun88?
            </summary>
            <div class="faq__content">
              <p>
                At Fun88, we offer a 35% commission for every new player you
                bring to our platform.
              </p>
            </div>
          </details>
          <details>
            <summary>
              From which countries, can I bring visitors to your website?
            </summary>
            <div class="faq__content">
              <p>
                We allow you to bring bettors from India to our platform to grow
                your network and earn referral income.
              </p>
            </div>
          </details>
          <details>
            <summary>
              What kind of support can I expect from Fun88 as an affiliate?
            </summary>
            <div class="faq__content">
              <p>
                Fun88’s team is committed to provide 24x7 support through
                WhatsApp, Telegram, Skype, and Emails. All the banners, mailers,
                Landing pages are updated from time to time and even shared with
                all of our affiliates at an individual level. Apart from this if
                any affiliate needs anything customized we ensure that we
                provide them at the earliest.
              </p>
            </div>
          </details>
          <details>
            <summary>
              Do I have to bring a minimum number of players to start earning a
              revenue share?
            </summary>
            <div class="faq__content">
              <p>
                Yes, you need to bring a minimum of 5 active players to start
                earning a revenue share. Of course, this can be dealt with case
                to case.
              </p>
            </div>
          </details>
          <details>
            <summary>
              How do I withdraw my commissions on a regular basis?
            </summary>
            <div class="faq__content">
              <p>
                The commission is processed on a monthly basis. If the affiliate
                wants the commission to be processed in INR then they need to
                have a minimum of 1000 INR for their commission to be processed.
                If it's in USD, EUR or any other currency then they must
                maintain a balance of USD 100 for there commission.
              </p>
            </div>
          </details>
          <details>
            <summary>
              Is it possible for me to promote Fun88’s products on multiple
              blogs?
            </summary>
            <div class="faq__content">
              <p>
                Yes, you can promote our products on multiple blogs. Over time,
                you can also see which blog is sending maximum traffic to our
                website. That will help you in concentrating your efforts on
                that blog.
              </p>
            </div>
          </details>
          <details>
            <summary>
              Can I have multiple affiliate accounts with Fun88?
            </summary>
            <div class="faq__content">
              <p>
                We permit affiliates to have only one account with us. So, you
                cannot have multiple affiliate accounts with Fun88. However, you
                can create sub affiliates accounts if you have multiple websites
                to reach.
              </p>
            </div>
          </details>
          <details>
            <summary>
              Can I use the content on Fun88’s website (blogs, etc.) to promote
              your products?
            </summary>
            <div class="faq__content">
              <p>
                Yes, you can. However, we encourage you to re-write, re-phrase
                the content and not plagiarise it. Make your own unique content.
              </p>
            </div>
          </details>
          <details>
            <summary>
              Is it possible for me to refer my friends and family as an
              affiliate to Fun88?
            </summary>
            <div class="faq__content">
              <p>
                Yes, we would want you to refer Fun88 to your friends and family
                and earn 10% of your sub affiliate earnings.
              </p>
            </div>
          </details>
        </div>
      </div>
      <div class="read-more">READ MORE</div>
    </div>
  </div>
</template>
<script>
import Banner from "../../core/components/Banner.vue";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {},
  methods: {
    tabChange(index) {
      this.tabIndex = index;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  background: #fff;
  .mod-banner {
    max-width: 1920px;
    width: 100%;
    height: 465px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 5;
    max-height: 600px;
  }
  .mod-affiliate {
    max-width: 1200px;
    margin: 0 auto;
    .affiliate-tab {
      width: 100%;
      padding: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      overflow: hidden;
      ul {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        display: block;
        -ms-overflow-style: none;
        scrollbar-width: none;
        list-style: none;
        padding: 0 0;
        margin: 0 0;
        li {
          box-sizing: border-box;
          white-space: break-spaces;
          overflow-x: overlay;
          position: relative;
          display: inline-block;
          min-width: 11rem;
          margin-right: 0.15rem;
          width: 33%;
          min-height: 40px;
          float: unset;
          color: #8e8e8e;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
          list-style-type: none;
        }
        .current {
          color: #00a6ff;
          border-bottom: 3px solid #00a6ff;
        }
      }
    }
    .agency-content {
      font-size: 16px;
      color: #404040;
      padding: 0 0 20px;
      word-break: break-word;
      .aff-info {
        position: relative;
        padding: 0;
        margin: 0;
      }
      h3 {
        font: 24px/1.5 "robotoregular", "Microsoft YaHei";
        color: #00a6ff;
        font-weight: bold;
        margin-top: 30px;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
      }
      p {
        padding-bottom: 15px;
        font-size: 16px;
        color: #404040;
        line-height: 24px;
        margin: 10px 0;
        padding: 0;
      }
      table {
        font-size: 20px;
        word-break: break-word;
      }
      .clearfix {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        list-style: none;
        width: 50%;
        height: 190px;
        padding: 0 0;
        margin: 0 0;
        li {
          box-sizing: border-box;
          width: 332px;
          height: 62px;
          margin: 15px 25px 15px 0;
          display: inline;
          border: 1px solid #25aae1;
          border-radius: 10px;
          box-sizing: border-box;
          list-style-type: none;
          a {
            font-size: 20px;
            color: #323232;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-left: 30px;
            text-decoration: none;
            outline: none;
          }
          img {
            width: 30px;
            height: 30px;
            vertical-align: top;
            border: 0;
            margin-right: 10px;
          }
          p {
            text-indent: 0;
            padding: 0;
            margin: 0;
            font-size: 14px;
            line-height: 1.5;
            strong {
              color: #00a6ff;
            }
          }
        }
        .whatsapp-affiliate {
          background-color: #00b200;
          border-color: #00b200;
          p {
            color: #fff;
            strong {
              color: #fff;
            }
          }
        }
        .telegram-affiliate {
          background-color: #009cec;
          p {
            color: #fff;
            strong {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .page-text {
    font-size: 16px;
    color: #404040;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    .layout {
      max-width: 1200px;
      width: 100%;
      overflow: hidden;
    }
    .text-left {
      text-align: left;
      margin: 0 auto;
    }
    h1 {
      font-size: 36px;
      font-weight: 600;
      color: #00a6ff;
      padding: 0;
      margin: 0;
    }
    p {
      word-break: break-word;
      line-height: 24px;
      font-size: 16px;
      color: #404040;
      padding: 0 0 15px 0;
      margin: 0;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-10 {
      margin-top: 10px;
    }
  }
  .read-more {
    width: 241px;
    height: 41px;
    margin: 30px auto;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #00a6ff;
    line-height: 41px;
    text-align: center;
    color: #00a6ff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
}
.contact-text.page-text h5 {
  font-size: 24px;
  padding: 0;
}
.contact-text.page-text p {
  padding-bottom: 15px;
  font-size: 16px;
}
.contact-text.page-text a {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: bold;
  display: inline !important;
  text-decoration: none !important;
}
.mt-0,
mt-1 {
  margin-top: 0px;
}
@media (max-width: 800px) {
  .mt-0 {
    margin-top: -20px !important;
  }
  .mt-1 {
    margin-top: -5px !important;
  }
}
.page-text h1,
.page-text h2,
.page-text h3,
.page-text h4,
.page-text h5,
.page-text h6 {
  font-size: 36px;
  font-weight: 600;
  color: #00a6ff;
}

.page-text h2,
.page-text h3,
.page-text h4,
.page-text h5,
.page-text h6 {
  text-align: left;
}

.page-text,
.agency-content {
  font-size: 16px;
  color: #404040;
}

.page-text p,
.agency-content p {
  padding-bottom: 15px;
  font-size: 16px;
  color: #404040;
}

.page-text b {
  color: #00a6ff;
}

.page-text .button {
  background-color: #fff;
  box-shadow: 0px 5px 10px #00000029;
  border: 4px solid #00a6ff;
  border-radius: 91px;
  color: #00a6ff;
  padding: 16px 32px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin: 1em auto;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  width: 50%;
}

.button:hover {
  opacity: 0.9;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-right: -3px;
  margin-left: -3px;
}

.bg-white {
  box-shadow: 0px 0px 15px #00a6ff38;
  margin: 1em;
  background-color: white;
}

.col-lg {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row [class*="col"] {
  position: relative;
}
/* FAQ */

.faq details summary {
  color: #00a6ff;
  padding: 18px 7px;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  position: relative;
  list-style: none;
  font-size: 20px;
  font-weight: 500;
}

.faq details summary:last-child {
  border-bottom: 1px solid #e3e3e3;
}

.faq details summary::after {
  position: absolute;
  background: url(https://www.fun88.com/in/fimg/i202202e68f6fae238047db810df10bce08686b.png)
    no-repeat scroll;
  right: 20px;
  width: 26px;
  height: 26px;
  content: " ";
}

.faq details summary::marker {
  display: none;
}

.faq details[open] {
  background-color: #f8f8f8;
}

.faq details[open] summary ~ * {
  -webkit-animation: sweep 0.5s ease-in-out;
  animation: sweep 0.5s ease-in-out;
}

.faq details[open] summary {
  background-color: #f8f8f8;
  padding: 18px 15px;
  border-bottom: 1px solid #f8f8f8;
}

.faq details[open] summary::after {
  position: absolute;
  background: url(https://www.fun88.com/in/fimg/i2022021ecfae484df44dd19c3edd2c454f5785.png)
    no-repeat scroll;
  right: 20px;
  width: 26px;
  height: 26px;
  content: " ";
}

.faq details[open] .faq-content {
  border-top: 0px solid #e3e3e3;
}

.page-text .faq__content {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-top: 0px solid #e3e3e3;
  padding: 0 40px 10px 15px;
}

.page-text .faq__content p {
  margin: 0;
  color: #707071;
}

.faq .button {
  background-color: #00a6ff;
  color: #fff;
  padding: 16px 32px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em auto;
  cursor: pointer;
  display: inline-block;
  border: 1px;
  text-decoration: none;
  border-radius: 0;
}
/* FAQ */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

@media (max-width: 800px) {
  .mb-20 {
    margin-bottom: 15px;
  }
  .mt-20 {
    margin-top: 15px;
  }
  .mb-30 {
    margin-bottom: 20px;
  }
  .mt-30 {
    margin-top: 20px;
  }
  .mb-40 {
    margin-bottom: 25px;
  }
  .mt-40 {
    margin-top: 25px;
  }
  .mb-50 {
    margin-bottom: 30px;
  }
  .mt-50 {
    margin-top: 30px;
  }
  .faq details summary {
    padding: 18px 30px 18px 7px;
  }
  .faq details summary::after,
  .faq details[open] summary::after {
    right: 10px;
    width: 20px;
    height: 20px;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
  }
  .page-text .faq__content {
    padding: 0 15px 10px;
  }
  .row {
    flex-direction: column;
    margin: 0;
  }
}

@media (min-width: 801px) {
  .page-text h1,
  .page-text h2,
  .page-text h3,
  .page-text h4,
  .page-text h5,
  .page-text h6 {
    font-size: 36px;
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}
</style>